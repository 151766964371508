import type { NextPage } from 'next'
import Head from 'next/head'
import { Layout, Main } from 'layout'
import PageContentHome from 'pageContent/Home'

const Home: NextPage = () => (
  <Layout>
    <Head>
      <title>Lebensfragen klären - Krisen meistern | FrauenCoach</title>
      <meta name="description" content={`Lebensfragen klären - Krisen meistern ● Ihr Coach ● Kein Risiko, nachhaltig und individuell ● Identität, Beziehungen, Gesundheit, Balance, Selbstbestimmung`} />
      <meta content={`Coach Berlin, Frauen-Coach, Frauencoach, Coach für Frauen, Identität, Beziehungen, Gesundheit, Balance, Selbstbestimmung`} name="keywords"></meta>
      <meta content="index,follow" name="robots"></meta>
    </Head>
    <Main>
      <PageContentHome />
    </Main>
  </Layout>
)

export default Home

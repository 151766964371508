import { FC, useEffect, useState } from 'react'
import Image from 'next/image'
import {
  Col,
  Card,
  CardContent,
  CardImage,
  Container,
  Row,
  Section,
} from 'atoms'
import { useWindowDimensions } from 'services/layout'
import { ContactCard } from 'organisms'
import Styles from './Home.module.sass'

import ImgPriv from 'assets/images/content/frauencoach-privatpersonen.jpeg'
import ImgComp from 'assets/images/content/frauencoach-firmen.jpeg'
import ImgCourse from 'assets/images/content/frauencoach-seminare.jpeg'

const PageContentHome: FC<unknown> = () => {
  const { width: windowWidth } = useWindowDimensions()
  const [cardImageHeight, setCardImageHeight] = useState(250)

  // We cannot use useMemo here since it produces a
  // hydration error:
  // https://nextjs.org/docs/messages/react-hydration-error
  useEffect(() => {
    if (windowWidth >= 1200) {
      setCardImageHeight(225)
      return
    }
    if (windowWidth >= 992) {
      setCardImageHeight(175)
      return
    }
    if (windowWidth >= 768) {
      setCardImageHeight(125)
      return
    }
    setCardImageHeight(250)
  }, [windowWidth])

  return (
    <>
      <Section background='rose'>
        <Container>
          <h1 className="text-center">
            Lebensfragen klären - Krisen meistern
          </h1>
          <div className={Styles.cardsRow}>
            <Card href="/angebot-privatpersonen">
              <CardImage
                first
                labelColor="brown"
                label="Privatpersonen"
                height={cardImageHeight}
                image={(
                  <Image
                    src={ImgPriv}
                    placeholder="blur"
                    layout="fill"
                    objectFit="cover"
                    alt="FrauenCoach für Privatpersonen"
                  />
                )}
              />
              <CardContent last>
                <p className='text-smaller'>
                  {/* eslint-disable-next-line max-len */}
                  Ihr Schlüssel für ein gelingendes und selbstbestimmtes Leben in Balance. Ich bin an Ihrer Seite bei Veränderungsprozessen und in akuten Krisenzeiten.
                </p>
              </CardContent>
            </Card>
            <Card href="/angebot-firmen">
              <CardImage
                first
                labelColor="blue"
                label="Firmen"
                height={cardImageHeight}
                image={(
                  <Image
                    src={ImgComp}
                    placeholder="blur"
                    layout="fill"
                    objectFit="cover"
                    alt="FrauenCoach für Firmen"
                  />
                )}
              />
              <CardContent last>
                <p className='text-smaller'>
                  {/* eslint-disable-next-line max-len */}
                  Maßgeschneiderte Angebote im Rahmen der betrieblichen Gesundheitsförderung und der Frauenförderung.
                </p>
              </CardContent>
            </Card>
            <Card href="/seminare-workshops">
              <CardImage
                first
                labelColor="green"
                label="Seminare &amp; Workshops"
                height={cardImageHeight}
                image={(
                  <Image
                    src={ImgCourse}
                    placeholder="blur"
                    layout="fill"
                    objectFit="cover"
                    alt="FrauenCoach für Seminare"
                  />
                )}
              />
              <CardContent last>
                <p className='text-smaller'>
                  {/* eslint-disable-next-line max-len */}
                  Gebündelte Kompetenz-Gemeinsam lernen. Exklusive Konzepte für Frauen für private und berufliche Kontexte.
                </p>
              </CardContent>
            </Card>
          </div>
          <ContactCard />
        </Container>
      </Section>
      {/* <Section>
        <Container>
          <h2>Was kann ich für Sie tun?</h2>
          <p>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </p>
        </Container>
      </Section> */}
      <Section background='vintage'>
        <Container>
          <Row>
            <Col>
            </Col>
            <Col>
              <p>
                {`Cordelia Schmidt`}
                <br />
                {`Steinstraße 15, 10119 Berlin`}
                <br />
                {`Coaching im Büro und per Telefon`}
                <br />
                <span>{`0176 20171385`}</span>
              </p>
            </Col>
            <Col>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default PageContentHome
